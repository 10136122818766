
import PageNavbar from '@/components/page_navbar'
import InputTelephone from '@/components/input_telephone'
import PasswordField from '@/components/form/password_field'
import ResetPassword from '@/components/reset_password_modal'
import { user_password } from '@/lib/validators'
import dev_env from '@/mixins/dev_env'

export default {
  mixins: [dev_env],

  props: {
    shouldRedirect: { type: Boolean, default: true },
    fromPage: { type: Boolean, default: true }
  },

  components: {
    PageNavbar,
    InputTelephone,
    PasswordField,
    ResetPassword
  },

  data: -> {
    show: false,
    dialog: false,
    login: if @dev_env() then '+27111111111' else '',
    password: if @dev_env() then 'password' else'',
    show_password: false
  },

  destroyed: ->
    @$store.commit('account/flush_errors')
  ,

  watch: {
    logged_in: (value) ->
      destination = @$route.query?.redirect_to || '/'
      if value then @$router.push(destination).catch (error) -> error
  },

  computed: {
    loading: ->
      @$store.state.account.loading
    ,
    logged_in: ->
      @$store.getters['account/logged_in']
    ,
    login_error: ->
      @$store.state.account.errors?.login
    ,
    password_error: ->
      @$store.state.account.errors?.password
    ,
    alert: ->
      !!@$route.query?.redirect_to

    register_url: ->
      path = "/register"
      path += "?redirect_to=#{@$route.query.redirect_to}" if !!@$route.query?.redirect_to
      path
  },

  methods: {
    submit: ->
      if @$refs.login_form.validate()
        @$store.dispatch("account/sign_in", { login: @login.split(' ').join(''), @password })
        .then =>
          if !@login_error && !@password_error then @$store.dispatch('layout/notify', @$t('popup_account_sign_in_ok'))
  }
}
